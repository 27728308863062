import React from 'react';

import GuideLink from '../../components/GuideLink';
import Indicators from './Indicators';
import { createMarkup } from '../../../utilities';

export default function GuideArchiveListItems({ guides, language }) {
    let year;

    return guides.map((guide) => {
        const currentYear = guide.attributes.sort_date?.split('-')[0];
        // display the year if the year changes
        // don't add year labels on institutional guides
        const displayYear = (
            guide.attributes.guide_type !== 'institutional' &&
            (!year || currentYear !== year)
        ) ? ' active' : '';
        year = currentYear;

        let guideMedia;
        if (guide.attributes.primary_media_html) {
            guideMedia = <div dangerouslySetInnerHTML={ createMarkup(guide.attributes.primary_media_html) }/>
        }

        return (
            <li key={ guide.id }
                className={ 'guide-list-item' }>
                <h3 className={ `guide-list-item__year${ displayYear }` }>{ year }</h3>
                <GuideLink to={ `${ guide.id }?language=${ language }` } scrollToTop={ true }>
                    { guideMedia }
                    <div className="list-item__text">
                        <h2 className="list-item__title"
                            dangerouslySetInnerHTML={ createMarkup(guide.attributes.title) }/>
                        <p className="guide-list-item__indicators">
                            { guide.attributes.guide_stops.length > 0
                                ? <Indicators guide={ guide } language={ language }/>
                                : I18n[language].empty_guides
                            }
                        </p>
                    </div>
                </GuideLink>
            </li>
        );
    });
}
