import { getCssVariable } from './utilities';

// calculate the element's height including its vertical margin
function height(query, value = 'marginTop') {
    const el = document.querySelector(query);

    if (!el || getComputedStyle(el).display === 'none') return 0;

    return el.offsetHeight + Number(getComputedStyle(el)[value].replace('px', ''));
}

function calculateHeight(aspectRatio) {
    const mediaWrap = document.querySelector('.shuffle__media-wrap');
    const maxHeight = mediaWrap.offsetWidth / aspectRatio;
    const viewportMargin = getCssVariable('--spacing-large');
    const navHeight = height('.header', 'marginBottom');
    const shuffleNavHeight = height('.shuffle-nav', 'marginBottom');
    const descriptionHeight = height('.shuffle__description');
    const linkHeight = height('.shuffle__link');
    const audioHeight = height('.audio-card');
    const mobileTitleHeight = window.isSmall ? height('.shuffle__title-area', 'marginBottom') : 0;
    const buttonAreaHeight = window.isSmall ? height('.shuffle__button-area') : 0;

    // Calculate the media item height by subtracting the height of the other elements on the page
    // This allows the whole shuffle view to fit in the viewport
    const mediaHeight = (
        navHeight +
        shuffleNavHeight +
        viewportMargin +
        descriptionHeight +
        linkHeight +
        audioHeight +
        mobileTitleHeight +
        buttonAreaHeight
    );
    // 100svh is the safe area of the screen, which excludes the browser's nav menu on mobile devices
    mediaWrap.style.height = `calc(100svh - ${ mediaHeight }px)`;
    // Make sure the media area is not taller than necessary
    mediaWrap.style.maxHeight = `${ maxHeight }px`;
}

export function adjustShuffleImageHeight() {
    const mediaWrap = document.querySelector('.shuffle__media-wrap');
    const shuffleImage = mediaWrap?.querySelector('.image');
    const shuffleVideo = mediaWrap?.querySelector('.shuffle__video');

    if (!shuffleImage && !shuffleVideo) return;

    const element = shuffleImage || shuffleVideo;
    const aspectRatioStyle = element.style.aspectRatio;

    if (!aspectRatioStyle.match(/^(auto )?\d+ \/ \d+$/)) return;

    const aspectRatio = aspectRatioStyle
        .replace('auto ', '')
        .split(' / ')
        .reduce((quotient, val) => quotient / val);

    calculateHeight(aspectRatio);
}

function shuffleAnalytics() {
    if (!useAnalytics) {
        return;
    }

    const wrapper = document.querySelector('.shuffle__wrap');
    if (wrapper) {
        const title = wrapper.querySelector('h1')?.textContent;

        wrapper.addEventListener('click', (e) => {
            const shuffleButtonEl = e.target.closest('.shuffle__button-link');
            const shuffleButtonMobileEl = e.target.closest('.shuffle__button-link-mobile');
            const shuffleActionEl = e.target.closest('.shuffle__link a');
            const shuffleDescriptionEl = e.target.closest('.shuffle__type-description a');
            const shuffleAudioEl = e.target.closest('.audio-player__play');

            if (shuffleButtonEl || shuffleButtonMobileEl) {
                dataLayer.push({
                    event: 'buttons',
                    label: 'shuffle',
                    value: 'main area',
                });

            } else if (shuffleActionEl) {
                dataLayer.push({
                    event: 'buttons',
                    label: 'shuffle click call to action',
                    value: shuffleActionEl.href,
                });

            } else if (shuffleDescriptionEl) {
                dataLayer.push({
                    event: 'buttons',
                    label: 'shuffle click type description link',
                    value: shuffleDescriptionEl.href,
                });

            } else if (shuffleAudioEl) {
                dataLayer.push({
                    event: 'buttons',
                    label: 'shuffle play audio',
                    value: title,
                });
            }
        });
    }
}

export default function setupShuffle() {
    if (!document.querySelector('.shuffle__wrap')) {
        return;
    }

    adjustShuffleImageHeight();
    shuffleAnalytics();
}
