//=================================================
// Guide pagination
//=================================================

import React from 'react';
import { isMobileGuide } from '../../utilities';
import PaginationLink from './PaginationLink';

export default function Pagination({ page: pageProp, totalGuides, listItemsLength }) {
    const page = parseInt(pageProp) || 1;
    const paginating = false;
    const guidesPerPage = 30;
    const totalPages = Math.ceil(totalGuides / guidesPerPage);

    if (isMobileGuide() || !(totalPages > 1 && listItemsLength > 0)) return null;

    const prevButton = () => {
        if (page > 1) {
            return (
                <li className="pagination__link pagination__link--prev">
                    <PaginationLink className="pagination__btn pagination__btn--prev"
                        aria-label="Previous"
                        disabled={ paginating }
                        type="button"
                        page={ page - 1 } />
                </li>);
        }
        return null;
    };

    const nextButton = () => {
        if (page < totalPages) {
            return (
                <li className="pagination__link pagination__link--next">
                    <PaginationLink className="pagination__btn pagination__btn--next"
                        aria-label="Next"
                        disabled={ paginating }
                        type="button"
                        page={ page + 1 } />
                </li>);
        }
        return null;
    };

    const paginationItem = (index) => (
        <li key={ index } className="pagination__link">
            <PaginationLink className={ `pagination__btn${ index === page ? ' active' : ' nope' }` }
                disabled={ paginating }
                type="button"
                page={ index }
            >
                { index }
            </PaginationLink>
        </li>
    );

    const paginationDash = (index) => (<span key={ index } className="pagination__dash">&mdash;</span>);

    let hyphen = false;
    const pageNumberArray = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumberArray.push(i);
    }
    const pageNumbers = pageNumberArray.map((index) => {
        if (totalPages >= 5) {
            switch (page) {
                case 1:
                    if (index <= 2 || index === totalPages) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case 2:
                    if (index <= 3 || index === totalPages) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case 3:
                    if (index <= 4 || index === totalPages) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case totalPages:
                    if (index >= totalPages - 1 || index === 1) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case totalPages - 1:
                    if (index >= totalPages - 2 || index === 1) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case totalPages - 2:
                    if (index >= totalPages - 3 || index === 1) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                default:
                    return paginationItem(index);
            }

        } else {
            return paginationItem(index);
        }
    });

    return (
        <div className={ 'pagination-wrapper' }>
            <ul className={ 'pagination' }>
                { prevButton() }
                { pageNumbers }
                { nextButton() }
            </ul>
        </div>
    );
}
