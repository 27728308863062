import React from 'react';

import { createMarkup } from '../../../utilities';
import StopItemTypes from './StopItemTypes';

export default function PlaylistItem(props) {
    const {
        playlistStop,
        index,
        stop,
        language,
        handleStopChange,
    } = props;
    const title = (playlistStop.attributes.title_es && language === 'es') ?
        playlistStop.attributes.title_es :
        playlistStop.attributes.title;

    return <li key={ index } className="playlist-item-wrapper">
        <a className={ `playlist-item${ (stop && stop?.id === playlistStop.id) ? ' active' : '' }` }
           href={ `${ window.location.pathname }?language=${ language }&stop=${ playlistStop.id }&open=true` }
           onClick={ (e) => handleStopChange(e, playlistStop) }>
            <div className="playlist-item__text-wrapper">
                <span className="playlist-item__number body-large">{ playlistStop.attributes.stop_number }</span>
                <h2 className="playlist-item__title body-large"
                    dangerouslySetInnerHTML={ createMarkup(title) }>
                </h2>
                <StopItemTypes language={ language } stop={ playlistStop }/>
            </div>
            <div className="playlist-item__media"
                 dangerouslySetInnerHTML={ createMarkup(playlistStop.attributes.cover_media_html) }>
            </div>
        </a>
    </li>;
}
