import React from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

import PageTransition from '../components/PageTransition';
import AudioSetup from '../components/AudioSetup';
import Footer from './Footer';

export default function Main({ mobileGuide }) {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    return (
        <>
            <PageTransition pathname={ pathname } page={ page }>
                <div>
                    <Outlet />
                    { mobileGuide && (<Footer />) }
                </div>
            </PageTransition>
            <AudioSetup />
        </>
    );
}
