//=================================================
// Sliders
//=================================================
import { playHandler } from './videos';

const Flickity = require('flickity');
const imagesLoaded = require('imagesloaded');

// For whatever reason videos in rails don't always start on their own with flickity
function setupSliderVideos() {
    document.querySelectorAll('.slider .video-native').forEach((video) => {
        // Check if the video should autoplay and is paused, if so play it
        if (video.hasAttribute('autoplay') && video.paused) {
            playHandler(video);
        }
    });
}

function setupSliderArrows(slider) {
    // Hide arrows if there's no non-visible items
    if (slider.querySelectorAll('.slider-item:not(.is-selected)').length === 0) {
        slider.classList.add('no-arrows');
    }
}

export function resizeSlider(slider) {
    const flkty = new Flickity(slider);
    flkty.resize();

    setupSliderArrows(slider);
}

export function resizeSliders() {
    document.querySelectorAll('.slider').forEach((el) => {
        resizeSlider(el);
    });
}

export function setupSliders() {
    document.querySelectorAll('.slider--uninitialized').forEach((slider) => {
        const flkty = new Flickity(slider, {
            adaptiveHeight: true,
            accessibility: true,
            cellAlign: 'left',
            contain: true,
            freeScroll: true,
            imagesLoaded: true,
            pageDots: false,
            resize: true,
            watchCSS: true,
            on: {
                ready: () => {
                    setupSliderVideos();
                },
            },
        });

        // Resize slider once images have loaded
        imagesLoaded(slider, () => {
            resizeSlider(slider);
        });

        slider.classList.remove('slider--uninitialized');
    });
}
