//=================================================
// Footnotes
//=================================================
import { isAdmin, scrollToElementIfNecessary } from './utilities';

const imagesLoaded = require('imagesloaded');


// Re-adjust sidenotes as necessary, assuming they are starting in the correct location
export function adjustFootnotes() {
    const footnotes = document.querySelectorAll('.footnote');
    const sidenotesContainer = document.querySelector('.sidenotes');
    const sidenotes = document.querySelectorAll('.sidenote');
    const offsets = [];

    if (sidenotes.length === 0) {
        return;
    }

    for (let i = 0; i < sidenotes.length; i++) {
        // Place sidenotes inline with footnote indicators
        // ...by doing this with relative positioning it should be a bit more resilient
        const footnoteOffset = footnotes[i].getBoundingClientRect();
        const sidenotesContainerOffset = sidenotesContainer.getBoundingClientRect();

        let difference = footnoteOffset.top - sidenotesContainerOffset.top;
        if (difference < 0) {
            difference = 0;
        }
        sidenotes[i].style.top = `${ difference }px`;

        // Keep track of all the offsets
        offsets[i] = sidenotes[i].getBoundingClientRect();

        // If this isn't the first sidenote, check if it overlaps the previous one
        if (i > 0) {
            const difference = (offsets[i - 1].top + offsets[i - 1].height) - offsets[i].top;
            const overlap = (offsets[i - 1].top + offsets[i - 1].height) > offsets[i].top;

            if (overlap) {
                // If they overlap, shift the current sidenote down
                const newTop = parseInt(sidenotes[i].style.top) + difference;
                sidenotes[i].style.top = `${ newTop }px`;
                offsets[i] = sidenotes[i].getBoundingClientRect();
            }
        }
    }

    // If the last sidenote goes past the bottom of the container add extra space to the container
    // ...otherwise the content will overflow into the "related" or footer areas
    const grid = sidenotesContainer.closest('.grid, .collection-bio');
    if (grid) {
        // Reset before getting new bounding rects and make sure sidenotes are visible
        grid.style.paddingBottom = '';
        if (getComputedStyle(sidenotesContainer).display !== 'none') {
            const lastSidenote = [...sidenotes].pop();
            if (lastSidenote) {
                const diff = lastSidenote.getBoundingClientRect().bottom - grid.getBoundingClientRect().bottom;
                if (diff > 0) {
                    grid.style.paddingBottom = `${ diff }px`;
                }
            }
        }
    }
}

export default function setupFootnotes() {
    if (isAdmin()) return;

    const footnotes = document.querySelectorAll('.footnote');
    const endnotesContainer = document.querySelector('.endnotes'); // Small screens only
    const sidenotesContainer = document.querySelector('.sidenotes');

    // Place all notes
    for (let i = 0; i < footnotes.length; i++) {
        const number = i + 1;
        const content = footnotes[i].innerHTML;

        // Replace the inline text with the footnote numerical indicator (if not already done)
        if (!document.getElementById(`footnote-${ number }`)) {
            footnotes[i].innerHTML = `<sup><a id="footnote-${ number }" href="#endnote-${ number }" class="footnote-indicator">${ number }</a></sup>`;
        }

        // Append to the end for small screens (check if it's already been created)
        if (endnotesContainer && !document.getElementById(`endnote-${ number }`)) {
            const endnote = document.createElement('p');
            endnote.classList.add('endnote');
            endnote.innerHTML = `<a id="endnote-${ number }" href="#footnote-${ number }" class="endnote-indicator">${ number }.</a> ${ content }`;
            endnotesContainer.appendChild(endnote);
        }

        // Append to the side for large screens (check if it's already been created)
        if (sidenotesContainer && !document.getElementById(`sidenote-${ number }`)) {
            const sidenote = document.createElement('p');
            sidenote.classList.add('sidenote');
            sidenote.innerHTML = `<a id="sidenote-${ number }" href="#footnote-${ number }" class="sidenote-indicator">${ number }.</a> ${ content }`;
            sidenotesContainer.appendChild(sidenote);
        }
    }

    // Scroll behavior for clicking on an indicator
    document.querySelectorAll('.footnote-indicator, .endnote-indicator, .sidenote-indicator').forEach((indicator) => {
        indicator.addEventListener('click', (e) => {
            e.preventDefault();

            // If sidenotes are visible and it's a footnote indicator, use the sidenote instead
            let el = document.querySelector(indicator.getAttribute('href'));
            if ((getComputedStyle(sidenotesContainer).display !== 'none') && indicator.classList.contains('footnote-indicator')) {
                el = document.querySelector(indicator.getAttribute('href').replace('endnote', 'sidenote'));
            }

            scrollToElementIfNecessary(el, 'smooth');
            el.parentElement.classList.add('highlight');
            el.focus({ preventScroll: true });

            setTimeout(() => {
                el.parentElement.classList.remove('highlight');
            }, window.transitionDurationSlow);
        });
    });

    if (footnotes.length) {
        // Do an initial layout
        adjustFootnotes();

        // Readjust everything once all images are loaded
        imagesLoaded('main', () => {
            adjustFootnotes();
        });
    }
}
