//=================================================
// Strobe text animation effect
//=================================================

import { getReducedMotion } from './user-preferences';

export default function setupStrobe() {
    document.querySelectorAll('.strobe').forEach((slider) => {
        // start animating when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                // Only strobe if reduced motion is off and element is in viewport
                if (!getReducedMotion() && entry.isIntersecting) {
                    slider.classList.add('strobe__active');
                }
            });
        });

        observer.observe(slider);
    });
}
