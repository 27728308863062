//=================================================
// Add to calendar component
//=================================================
import { google } from 'calendar-link';

function pad(i) {
    return i < 10 ? `0${ i }` : `${ i }`;
}

function icalDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1);
    const day = pad(date.getUTCDate());
    const hour = pad(date.getUTCHours());
    const minute = pad(date.getUTCMinutes());
    const second = pad(date.getUTCSeconds());
    return `${ year }${ month }${ day }T${ hour }${ minute }${ second }Z`;
}

// calendar-link's ical solution does not work on all devices
function downloadIcal(event) {
    const element = event.target;

    if (element.classList.contains('add-to-calendar__ical')) {
        const wrap = document.getElementById('add-to-calendar');
        const { dataset } = wrap;
        const ics = encodeURI(`data:text/calendar;charset=utf8,${ [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            `DTSTART:${ icalDateFormat(dataset.start) }`,
            `DTEND:${ icalDateFormat(dataset.end) }`,
            `SUMMARY:${ dataset.title }`,
            `DESCRIPTION:${ dataset.description }`,
            `LOCATION:${ dataset.location }`,
            'END:VEVENT',
            'END:VCALENDAR',
        ].join('\n') }`);
        element.href = ics;
        element.download = 'event.ics';
    }
}

// insert the links that are generated by the calendar-link npm package
export function setupAddToCalendar() {
    const wrap = document.getElementById('add-to-calendar');
    if (!wrap) {
        return;
    }

    const event = { ...wrap.dataset };
    const googleEl = wrap.querySelector('.add-to-calendar__google');

    googleEl.setAttribute('href', google(event));

    document.addEventListener('click', downloadIcal);
}
