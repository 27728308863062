//=================================================
// Responsive W (used on home)
//=================================================

import { queryParameters } from './utilities';

// W type rotates by day and can be overridden
function getWType() {
    const params = queryParameters();
    if (params.w && ['left', 'right', 'center'].includes(params.w)) {
        return params.w;
    }

    const day = new Date().getDay(); // Get the day of the week as a number (0-6)
    switch (day % 3) {
        case 0:
            return 'left';
        case 1:
            return 'right';
        default:
            return 'center';

    }
}

function showW() {
    const w = document.querySelector('.w');
    w.classList.add('active');
}

// Reset all special changes made for supporting the W on home
function hideW() {
    const navigation = document.querySelector('.navigation');
    const w = navigation.querySelector('.w');
    const miniTitle = navigation.querySelector('.mini-header__title');

    w.classList.remove('active');
    w.style.opacity = '';
    miniTitle.style.opacity = '';
    navigation.classList.remove('inactive-w');
}

function isActiveW() {
    return window.location.pathname === '/';
}

// Track handlers globally so we can add/remove listeners as needed
let handleScroll;
let handleSetup;

export function setupW() {
    // Remove any existing listeners so they aren't re-added
    if (handleSetup) {
        window.removeEventListener('resize', handleSetup);
        handleSetup = null;
    }
    if (handleScroll) {
        window.removeEventListener('scroll', handleScroll);
        handleScroll = null;
    }

    // Don't run if there's no w
    const w = document.querySelector('.w');
    if (!w) {
        return;
    }

    // If the W isn't supposed to be active, hide it and bail out
    if (!isActiveW()) {
        hideW();
        return;
    }

    // Setup
    const wType = getWType();
    const pointsOptions = {
        'left': { 'large': '0,0 0,100 x,0 x,y 100,0', 'small': '0,0 0,100 x,0 x,y 100,0' },
        'right': { 'large': '0,0 x,100 x,0 100,y 100,0', 'small': '0,0 x,100 x,0 100,y 100,0' },
        'center': { 'large': '0,0 25,100 50,0 75,y 100,0', 'small': '0,0 25,100 50,0 75,y 100,0' }
    };
    const ratio = 5.9575; // 4766 : 800 = 5.9575 : 1

    // These all need to be updated because turbo
    let container = document.querySelector('.main'); // This changes due to Turbo
    const navigation = document.querySelector('.navigation');
    const text = w.querySelector('.w__text');
    const svg = w.querySelector('.w__svg');
    const polyline = svg.querySelector('polyline');
    const miniHeaderWrapper = navigation.querySelector('.mini-header-wrapper');
    const miniTitle = navigation.querySelector('.mini-header__title');
    const miniHeaderRight = miniHeaderWrapper.querySelector('.mini-header__right');

    let bodyPadding;
    let inputHeight;
    let marginWidth;
    let maxHeight;
    let maxWidth;
    let navHeight;
    let navWidth;
    let newLeft = 'auto';
    let newRight = 'auto';
    let offsetY;
    let opacityFadeHeight;
    let points;
    let spacingSmall;
    let ticking = false;
    let wSpacing;
    let leftCurrent;
    let leftLarge;
    let leftSmall;
    let rightCurrent;
    let rightLarge;
    let rightSmall;

    // Determine width and height of the text
    function generateTextDimensions(height) {
        let width = maxWidth * leftCurrent;
        const newHeight = (height * width) / (width + ratio * height);
        let newWidth = ratio * newHeight;
        // About ~ 3.4% of WHITNEY text width is width of a single letter stem (163 / 1785)
        const spacing = newWidth * 0.034;
        // Left 1 spacing from left, center 0 additional spacing, right 1 spacing from right
        if (['left', 'right'].includes(wType)) {
            newWidth = newWidth - spacing;
        }

        return [newWidth, newHeight, spacing];
    }

    function drawText() {
        let height = maxHeight - offsetY;
        let newHeight = 0;
        let newWidth = 0;
        let spacing = 0;
        if (height > 0) {
            if (wType === 'left') {
                [newWidth, newHeight, spacing] = generateTextDimensions(height);
                newLeft = spacing + 'px';
            } else if (wType === 'right') {
                [newWidth, newHeight, spacing] = generateTextDimensions(height - (wSpacing));
                newRight = `${ (maxWidth * rightCurrent) + spacing }px`;
            } else {
                [newWidth, newHeight] = generateTextDimensions(height);
                newLeft = `${ (((maxWidth * leftCurrent) - newWidth) * leftCurrent) }px`;
            }
        }

        // Very jittery on safari if height isn't also set
        Object.assign(text.style, {
            left: newLeft,
            right: newRight,
            width: newWidth + 'px',
            height: newHeight + 'px'
        });
    }

    function update() {
        ticking = false;
        // There's slight whitespace above the button, so visually account for that
        const buttonMargin = 8; // calc((var(--nav-height) - var(--nav-link-height)) * 0.5) equals 8
        let newHeight = Math.max(0, maxHeight - offsetY);
        let shortPointHeight;
        let wOpacity = 1;
        let titleOpacity = 0;
        // Track space remaining before fade begins
        const remainingHeight = maxHeight - offsetY - navHeight;

        if (window.isNavSmall) {
            // Much simpler on small screens since nothing is "sitting"
            newHeight = Math.max(0, maxHeight - offsetY);
            shortPointHeight = 100;
            titleOpacity = 1;
            wOpacity = ((newHeight / opacityFadeHeight) * 100) / 100;
        } else {
            // Shorten the point that "sits" on the nav
            shortPointHeight = Math.max(0, 100 * ((remainingHeight + buttonMargin) / newHeight));

            // Adjust w opacity if we've past the fade-in point
            if (remainingHeight <= opacityFadeHeight) {
                wOpacity = Math.max(0, remainingHeight / opacityFadeHeight);
            }

            const navigationTop = bodyPadding + maxHeight - navHeight + wSpacing;
            const distanceToStickyPoint = navigationTop - offsetY;
            // Calculate the distance ignoring the button adjustment
            const titleOpacityDistance = Math.abs(maxHeight - navigationTop - navHeight);

            // Adjust title opacity if we've fully faded out the w
            if (remainingHeight <= 0) {
                // Calculate how much space there is to fade in the title
                titleOpacity = Math.min(1, 1 - (distanceToStickyPoint / titleOpacityDistance));
            }
        }

        Object.assign(w.style, { opacity: wOpacity });
        Object.assign(miniTitle.style, { opacity: titleOpacity });

        const newPoints = points.replaceAll('x', leftCurrent * 100).replaceAll('y', shortPointHeight);
        polyline.setAttribute('points', newPoints);

        // Fade in title based on height of the w
        // ...NOTE this is shortPointHeight without buttonMargin, because we space/time this ignoring that value for sanity's sake
        if (remainingHeight / newHeight <= 0) {
            miniTitle.classList.add('active');
        } else {
            miniTitle.classList.remove('active');
        }

        // Scrolled enough to change nav state and fix the mini-header
        if (offsetY >= (maxHeight + wSpacing + bodyPadding)) {
            navigation.classList.add('inactive-w');
        } else {
            navigation.classList.remove('inactive-w');
        }

        svg.style.height = `${ newHeight }px`;

        drawText();
    }

    // Must be named anonymous functions for proper listener handling
    handleSetup = function setup() {
        if (isActiveW()) {
            showW();

            // Determine margins and sizing, pulling everything out of the css
            const windowStyle = window.getComputedStyle(document.documentElement);
            container = document.querySelector('.main'); // This can change due to Turbo
            bodyPadding = parseInt(windowStyle.getPropertyValue('--body-padding'));
            inputHeight = parseInt(windowStyle.getPropertyValue('--input-height'));
            marginWidth = parseFloat(window.getComputedStyle(container).paddingLeft);
            maxHeight = parseInt(windowStyle.getPropertyValue('--w-height'));
            navHeight = parseInt(windowStyle.getPropertyValue('--nav-height'));
            spacingSmall = parseInt(windowStyle.getPropertyValue('--spacing-small'));
            wSpacing = parseInt(windowStyle.getPropertyValue('--w-spacing'));

            opacityFadeHeight = bodyPadding;
            navWidth = container.offsetWidth - (marginWidth * 2);
            maxWidth = navWidth;
            offsetY = window.scrollY;

            // Set w half variables
            let strokeWidth = parseFloat(window.getComputedStyle(polyline).strokeWidth);
            if (Number.isNaN(strokeWidth)) {
                strokeWidth = 0;
            }
            const buttonsLeftEdge = miniHeaderRight.getBoundingClientRect().x;
            if (wType === 'left') {
                // Set left section so that the w "sits" at the edge of the mini header
                const wLeftEdge = w.getBoundingClientRect().x;
                const usableWidth = buttonsLeftEdge - wLeftEdge + strokeWidth / 2;
                leftLarge = usableWidth / maxWidth;
            } else if (wType === 'right') {
                // Set left section so that the w "sits" off to the side of the mini header
                const wLeftEdge = w.getBoundingClientRect().x + wSpacing * 2;
                const usableWidth = buttonsLeftEdge - wLeftEdge + strokeWidth / 2;
                leftLarge = usableWidth / maxWidth;
            } else {
                leftLarge = .5;
            }

            rightLarge = 1 - leftLarge;
            leftSmall = .5;
            rightSmall = .5;

            // Set size specific values, and classes
            if (window.isNavSmall) {
                points = pointsOptions[wType].small;
                leftCurrent = leftSmall;
                rightCurrent = rightSmall;
            } else {
                points = pointsOptions[wType].large;
                leftCurrent = leftLarge;
                rightCurrent = rightLarge;
            }

            update();
            drawText();
            Object.assign(text.style, { right: newRight, display: 'block' });
        } else {
            hideW();
        }
    };

    function requestTick() {
        // Skip rendering using RAF until update has run
        if (!ticking) {
            requestAnimationFrame(update);
        }
        ticking = true;
    }

    // Must be named anonymous functions for proper listener handling
    handleScroll = function() {
        offsetY = window.scrollY;
        requestTick();
    };

    handleSetup(); // Initial run
    window.addEventListener('resize', handleSetup);
    window.addEventListener('scroll', handleScroll);
}
