//=================================================
// Guide navigation
//=================================================

import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getLanguage, isMobileGuide, pushGuideEvent } from '../utilities';
import { createMarkup } from '../../utilities';
import GuideLink from '../components/GuideLink';

export default function Navigation({ guide }) {
    const mobileGuide = isMobileGuide();
    const [searchParams, setSearchParams] = useSearchParams();
    const language = getLanguage(searchParams);
    const { pathname } = useLocation();
    const isHomepage = pathname === '/';

    function handleGuidesLanguageChange(e) {
        const targetLanguage = e.target.value;
        setSearchParams({ ...Object.fromEntries(searchParams), language: targetLanguage });

        pushGuideEvent({ label: 'language', language: targetLanguage, value: targetLanguage }, guide);
    }

    let title;
    let breadcrumbs;
    if (isHomepage) {
        if (mobileGuide) {
            title = I18n[language].guide;
        } else {
            title = I18n[language].audio_guides;
        }
    } else {
        title = guide?.attributes?.title;

        if (!mobileGuide) {
            breadcrumbs =
                <nav className="breadcrumbs-wrapper" aria-label="Breadcrumbs">
                    <ol className="breadcrumbs">
                        <li className="breadcrumbs__item arrow-link arrow-link--left">
                            <GuideLink className="breadcrumbs__link body-medium-bold"
                                       to={ `/?language=${ language }` }>{ I18n[language].audio_guides }</GuideLink>
                        </li>
                    </ol>
                </nav>;
        }
    }

    const titleEl =
        <h1 className={ `m-0${ isHomepage ? ' large' : '' }` } dangerouslySetInnerHTML={ createMarkup(title) }
            tabIndex="-1"/>;
    const languageSelect =
        <div className={ `select-wrapper${ mobileGuide ? ' select-wrapper--guide' : '' }` }>
            <select className="select guide-language-select"
                    aria-label="Guide language"
                    value={ language }
                    onChange={ (e) => handleGuidesLanguageChange(e) }>
                <option value="en">English</option>
                <option value="es">Español</option>
            </select>
        </div>;

    let headerTop;
    if (mobileGuide) {
        headerTop = titleEl;
    } else {
        headerTop =
            <div className="guide-header__top">
                { titleEl }
                { languageSelect }
            </div>;
    }

    return (
        <header className={ `guide-header${ !mobileGuide ? ' guide-header--archive' : '' }` }>
            { breadcrumbs }
            { headerTop }
            <nav className="guide-header__nav content-wrapper">
                <div className="guide-nav">
                    <GuideLink to={ `/?language=${ language }` }
                               className={ `guide-nav__close guide-back${ (!isHomepage ? ' active' : '') }` }>
                        { I18n[language].back }
                    </GuideLink>

                    { mobileGuide ? languageSelect : null }
                </div>
            </nav>
        </header>
    );
}
