//=================================================
// Application bundle
//=================================================
import '../stylesheets/application.scss';
/* eslint-disable import/first */
/* eslint no-console:0 */
// Add all images to manifest
//, /\.(gif|jpg|png|svg)$/i
const images = require.context('../images/', true);

// Import javascript modules
import { adjustLinkRails, navigationClose, setupNavigation } from '../javascript/modules/navigation';
import { setupAddToCalendar } from '../javascript/modules/add-to-calendar';
import { setupAudio } from '../javascript/modules/audio';
import { adjustCardRails, setupCards } from '../javascript/modules/cards';
import {
    setupCollectionExpandables, setupCollectionSearch, setupCollectionSync
} from '../javascript/modules/collection';
import { setupUserPreferences } from '../javascript/modules/user-preferences';
import { adjustExpandables, setupExpandables } from '../javascript/modules/expandables';
import setupCalendar from '../javascript/modules/calendar';
import setupCopyable from '../javascript/modules/copyable';
import setupDayPickers from '../javascript/modules/day-picker';
import setupFootnotes, { adjustFootnotes } from '../javascript/modules/footnotes';
import setupJumplinks from '../javascript/modules/jumplinks';
import { getAllFields, replaceHistory, setupLists } from '../javascript/modules/lists';
import setupMailchimp from '../javascript/modules/mailchimp';
import setupOnTheHour from '../javascript/modules/on-the-hour';
import setupOverlays from '../javascript/modules/overlays';
import setupShuffle, { adjustShuffleImageHeight } from '../javascript/modules/shuffle';
import { resizeSliders, setupSliders } from '../javascript/modules/sliders';
import setupStrobe from '../javascript/modules/strobe';
import setupTabFocus from '../javascript/modules/accessibility';
import setupTicketSelector from '../javascript/modules/event';
import { setupVideos, resizeVideos } from '../javascript/modules/videos';
import {
    adjustSticky,
    defineBreakpoints,
    queryParameters,
    scrollToElementIfNecessary,
    setupViewCounts,
} from '../javascript/modules/utilities';
import { setupTooltips } from '../javascript/modules/utilities';
import { setupW } from '../javascript/modules/w';
import { setupGuides } from '../javascript/modules/guides/setup';
import { setupAccordions } from '../javascript/modules/accordions';
import { createConsumer } from '@rails/actioncable';
import { adjustImageViewers, setupImageViewers } from '../javascript/modules/images';

// Reused variables
const params = queryParameters();

function initializeComponents(_e) {
    setupUserPreferences(); // Set global settings like dark and prefers-reduced-motion
    defineBreakpoints();

    setupAccordions();
    setupAddToCalendar();
    setupAudio();
    setupCards(); // MUST be before adjusting expandables
    setupCollectionExpandables();
    setupCollectionSearch();
    setupCollectionSync();
    setupCopyable();
    setupDisabledFrameLinks();
    setupDayPickers();
    setupExpandables();
    setupCalendar();
    setupFootnotes();
    setupGuides();
    setupImageViewers();
    setupJumplinks();
    setupOverlays();
    setupLists();
    setupMailchimp();
    setupNavigation();
    setupOnTheHour();
    setupSliders();
    setupShuffle();
    setupStrobe();
    setupTabFocus();
    setupTicketSelector();
    setupTooltips();
    setupW();
    setupVideos();
    setupViewCounts();

    adjustSticky();
    navigationClose(); // Close navigation once page has loaded, any earlier and it feels off
    adjustLinkRails();

    // artport links can open in windows of exact size (no perfect way to do this)
    document.querySelectorAll('.btn--artport').forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...
            el.addEventListener('click', (e) => {
                const { width, height } = el.dataset;
                if (width && height) {
                    e.preventDefault();
                    window.open(el.href, '', `width=${ width },height=${ height }`);
                }
            });
        }
    });

    // Misc analytics tracking across site
    if (useAnalytics) {
        // Ad blocker tracking
        const ad = document.createElement('ins');
        ad.className = 'AdSense';
        Object.assign(ad.style, {
            display: 'block', position: 'absolute', top: '-1px', height: '1px', opacity: 0,
        });
        document.body.appendChild(ad);
        dataLayer.push({
            event: 'ad_blocker',
            label: 'ad blocker',
            value: !ad.clientHeight,
        });

        // SEO related tracking suggested by Hudson Creative
        // Email clicks
        document.querySelectorAll('a[href^="mailto:"]').forEach((el) => {
            el.addEventListener('click', () => {
                dataLayer.push({
                    event: 'email_click',
                    value: el.href.replace('mailto:', ''),
                });
            });
        });

        // Maps clicks (both Google and Apple appear to start with maps.[domain]
        document.querySelectorAll('a[href^="https://maps."]').forEach((el) => {
            el.addEventListener('click', () => {
                dataLayer.push({
                    event: 'maps_click',
                    value: el.href,
                });
            });
        });

        // Phone number clicks
        document.querySelectorAll('a[href^="tel:"]').forEach((el) => {
            el.addEventListener('click', () => {
                dataLayer.push({
                    event: 'phone_click',
                    value: el.href.replace('tel:', ''),
                });
            });
        });
    }

    // Admin toolbar
    const toolbar = document.querySelector('.admin-toolbar');
    if (toolbar) {
        // Shrink toolbar
        toolbar.querySelectorAll('.admin-toolbar__item--shrink').forEach((el) => {
            el.addEventListener('click', () => {
                localStorage.setItem('toolbar-shrink', 'true');
                toolbar.classList.add('admin-toolbar--shrink');
            });
        });

        // Expand toolbar
        toolbar.querySelectorAll('.admin-toolbar__item--expand').forEach((el) => {
            el.addEventListener('click', () => {
                localStorage.setItem('toolbar-shrink', 'false');
                toolbar.classList.remove('admin-toolbar--shrink');
            });
        });

        // Set shrinked if previously shrinked
        if (localStorage.getItem('toolbar-shrink') === 'true') {
            toolbar.classList.add('admin-toolbar--shrink');
        }
    }

    // Admin toasts (can be viewed both in admin and public frontend if logged in)
    const consumer = createConsumer();
    const userId = document.querySelector('meta[name="user-id"]')?.getAttribute('content');
    if (userId) {
        // Open websocket connection to the server
        consumer.subscriptions.create({ channel: 'AdminChannel', id: userId }, {
            connected() {
                console.log('WS: connected');
            },
            disconnected() {
                console.warn('WS: disconnected');
            },
            received(response) {
                console.log('WS: response', response);

                // Show a flash/toast message
                if (response.message) {
                    const flashEl = document.getElementById('flash');
                    if (flashEl) {
                        flashEl.innerHTML = `
                        <div class="alert${ response.type ? ` alert--${ response.type }` : '' }">
                            <p class="m-0"><strong>${ response.message }</strong></p>
                            <button class="alert__close" type="button" aria-label="Close alert" onclick="this.parentElement.style.display = 'none';">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                    <path d="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z" style="fill: var(--color-text);"></path>
                                </svg>
                            </button>
                        </div>
                        `;
                    }
                }

                // Remove syncing status from any provided selector
                if (response.sync_selector) {
                    document.querySelectorAll(response.sync_selector).forEach((el) => {
                        el.disabled = false;
                        el.classList.remove('btn--syncing');
                    });
                }

                // Update any timestamps from any provided selector
                if (response.timestamp_selector) {
                    document.querySelectorAll(response.timestamp_selector).forEach((el) => {
                        el.innerHTML = 'just now';
                    });
                }

                // Replace partials in admin forms asynchronously
                // Currently only used in BuildAutogeneratedEvents
                if (response.body) {
                    const el = document.querySelector(response.selector);

                    if (el) {
                        el.innerHTML = response.body;
                        el.closest('fieldset.admin-form--updating')?.classList?.remove('admin-form--updating');
                    }
                }
            },
            rejected() {
                console.warn('WS: rejected');
            },
        });
    }

    // On the Hour previews
    const onTheHourPreviewEls = document.querySelectorAll('.on-the-hour-preview');
    onTheHourPreviewEls.forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...

            const submit = el.querySelector('.on-the-hour-preview__submit');
            submit.addEventListener('click', () => {
                let url = el.querySelector('.on-the-hour-preview__select').value;
                if (url.includes('?')) {
                    url = `${ url }&on-the-hour=true`;
                } else {
                    url = `${ url }?on-the-hour=true`;
                }
                submit.setAttribute('href', url);
            });
        }
    });

    // Show any announcements
    const announcement = document.querySelector('.announcement-wrapper');
    if (announcement) {
        const currentTime = Date.now();
        const lastAnnouncement = localStorage.getItem('announcement');
        // Only show if it's the first time within a day if they've previously closed it
        const withinDay = Number.isNaN(parseInt(lastAnnouncement)) || lastAnnouncement < (currentTime - 86400000);
        // Just don't show on guides with audio, things overlap with the design and not worth it
        const activeGuide = document.querySelector('.audio-wrapper--guide.active');

        if ((withinDay || params.announcement === 'true') && !activeGuide) {
            // Set announcement to active, then allow css animations to slide it up
            announcement.style.bottom = `${ -announcement.offsetHeight }px`;
            setTimeout(() => {
                announcement.classList.add('active');
                announcement.style.bottom = null;
            }, 1);

            announcement.querySelector('.announcement__close').addEventListener('click', () => {
                localStorage.setItem('announcement', currentTime);
                announcement.classList.remove('active');
            });
        }
    }

    // artport easter egg in the console
    if (window.location.pathname.toLowerCase().indexOf('/artport') > -1) {
        console.log('%cartport :)', 'font-family: system-ui; font-weight: bold; font-size: 2rem; padding: 0 .5rem .5rem 0; color: rgb(255, 0, 0); text-shadow: 2px 2px 0 rgb(255, 0, 255), 4px 4px 0 rgb(0, 0, 255);');
    }
}

document.addEventListener('DOMContentLoaded', (e) => {
    initializeComponents(e);

    // Anything that should only load once is below (e.g. listeners on document)

    document.addEventListener('keydown', (e) => {
        const primary = document.querySelector('.primary-nav');
        if (primary) {
            if (e.key === 'Escape') {
                navigationClose();
            }
        }
    });

    // Hide dropdown menu on outside click
    document.addEventListener('click', (e) => {
        const navigation = document.querySelector('.navigation')
        if (navigation) {
            const openNav = navigation.querySelector('.open');
            if (openNav) {
                const primaryNav = e.target.closest('.primary-nav');
                const miniMenu = e.target.closest('.mini-header__menu-small');
                const homeSmall = e.target.closest('.primary-nav-small-header__title');

                // Only close if the click was outside the nav + nav toggle button or is the small home button
                if ((!primaryNav && !miniMenu) || homeSmall) {
                    navigationClose();
                }
            }
        }
    });
});

// Add the custom data-turbo-target-top attribute to make all descendant links drive a full page load
// This is useful for user-generated rich text content that appears inside Turbo frames
const setupDisabledFrameLinks = () => {
    document.querySelectorAll('turbo-frame [data-turbo-target-top] a[href]').forEach((el) => {
        if (el.getAttribute('href').startsWith('/')) {
            el.setAttribute('target', '_top');
        }
    });
};

document.addEventListener('turbo:render', (e) => {
    // Sometimes Turbo frames get stuck on busy state when landing via back/forward buttons
    document.querySelectorAll('turbo-frame[aria-busy=true]').forEach((el) => {
        el.removeAttribute('aria-busy');
        el.removeAttribute('busy');
        el.removeAttribute('complete');
    });

    // Re-initialize any components added via Turbo render
    initializeComponents(e);
});

document.addEventListener('turbo:frame-render', (e) => {
    // Only scroll to the element if the top isn't in view (better than Turbo autoscroll handling)
    // ...and if it's not a turbo frame we want to ignore for UX reasons
    const ignoreSelectors = ['#exhibition_feature_section']
    const matches = ignoreSelectors.some(selector => e.target.matches(selector));
    if (!matches) {
        scrollToElementIfNecessary(e.target);
    }

    // Re-initialize any new components (like videos)
    initializeComponents(e);
});

// // Turbo form handling
document.addEventListener('turbo:submit-start', (e) => {
    // Add aria-busy since it doesn't get added automatically if we're doing turbo stream updates
    e.target.querySelectorAll('turbo-frame').forEach((el) => {
        el.setAttribute('aria-busy', 'true');
    });

    // Update history, should only be used with Turbo Streams (be VERY careful), everything else should be native frames
    if (e.target.closest('.list')?.dataset.history === 'true') {
        const allFields = getAllFields(e.target.closest('.list'));
        allFields.push({ name: 'page', value: null, invert: false });
        replaceHistory(allFields);
    }
});

document.addEventListener('turbo:submit-end', (e) => {
    // Remove busy/loading state from any finishing streams or frames
    e.target.querySelectorAll('turbo-frame').forEach((el) => {
        el.setAttribute('aria-busy', null);
    });
});

document.addEventListener('turbo:before-render', (e) => {
    // Because turbo strips event listeners in caching remove all initialized attributes since they'll have to be re-initialized
    event.detail.newBody.querySelectorAll('[data-initialized="true"]').forEach((el) => {
        el.removeAttribute('data-initialized');
    });
});

window.addEventListener('resize', () => {
    const breakpoint = 800;
    defineBreakpoints();
    adjustSticky();
    adjustShuffleImageHeight();
    adjustImageViewers();

    // Run certain resize events only when horizontal, not vertical, changes
    const width = window.innerWidth;
    if (width !== window.cacheWidth) {
        adjustCardRails(); // MUST be before adjusting expandables
        adjustExpandables();
        adjustFootnotes();
        adjustLinkRails();
        resizeVideos();
        resizeSliders();

        // If we're moving from small screen to large screen...
        if ((width >= breakpoint) && (window.cacheWidth < breakpoint)) {
            // Close small nav since the large nav doesn't hide the page background etc.
            navigationClose();
        }

        window.cacheWidth = width;
    }
});
