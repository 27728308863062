//=================================================
// Guide indicators
//=================================================
import React from 'react';

export default function Indicators({ guide, language }) {
    // Go through and organize what languages are available for each type
    const stopTypes = {};

    guide.attributes.guide_stops.forEach((stop) => {
        stop.data.attributes.item_types.forEach((itemTypes) => {
            const stopItemLanguage = itemTypes.item_language;
            const type = itemTypes.item_type;

            if (stopTypes[type]) {
                stopTypes[type].add(stopItemLanguage);
            } else {
                stopTypes[type] = new Set([stopItemLanguage]);
            }
        });
    });

    // List out all the available types, and if they're only available in english
    let indicators = [];
    const availableTypes = [
        'audio',
        'kids',
        'asl',
        'transcription',
        'verbal_description',
    ].filter((type) => stopTypes[type]);

    availableTypes.forEach((type) => {
        const indicatorText = (stopTypes[type].has(language))
            ? I18n[language][type]
            : I18n[language][`${ type }_english_only`];
        // Commenting out icons for now, pending getting icons for everything and really looking at the design
        // indicators.push(<span className="guide-list-item__indicator"
        //                       key={ type }>{ getTypeIcon(type) }{ indicatorText }</span>);

        indicators.push(indicatorText);
    });

    // For now make them a comma separated list
    indicators = indicators.join(', ');

    // let stopCount = '';
    // if (guide.attributes.guide_stops.length > 1) {
    //     stopCount = <>{ guide.attributes.guide_stops.length } { I18n[language].stops }: </>;
    // } else if (guide.attributes.guide_stops.length > 0) {
    //     stopCount = <>{ guide.attributes.guide_stops.length } { I18n[language].stop }: </>;
    // }

    return <>{ indicators }</>;
}
