import React from 'react';
import { uniq } from 'lodash';

import { getStopItems } from '../../utilities';

// function StopItemType({ language, type }) {
//     return <li className="playlist-item__type" key={ type }>
//         {/*{ getTypeIcon(type) || itemTypeDisplay(type, language) }*/ }
//         { itemTypeDisplay(type, language) }
//     </li>;
// }

export default function StopItemTypes({ language, stop }) {
    // Commenting out for now until icons are sorted
    //
    // Get a list of the types of stop_items that this stop contains
    // const types = uniq(
    //     getStopItems(stop, language)
    //         .map((item) => item.data.attributes.item_type),
    // );
    //
    // return <ul className="playlist-item__types">
    //     { types.map((type) => <StopItemType key={ type } language={ language } type={ type }/>) }
    // </ul>;

    const types = uniq(
        getStopItems(stop, language).map((item) => {
            if (item.data.attributes.item_language === language) {
                return I18n[language][item.data.attributes.item_type];
            } 
                return I18n[language][`${ item.data.attributes.item_type }_english_only`];
            
        }),
    );

    return <span>{ types.join(', ') }</span>;
}
