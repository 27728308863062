import OpenSeadragon from 'openseadragon';

function shouldLoadViewer(img, canvas, isActive) {
    const zoomRatio = 1.25; // Ratio for how much bigger the image needs to be than the canvas to allow zooming

    // Compare the loaded element to its rendered size
    if (isActive) {
        return img.naturalWidth >= canvas.clientWidth * zoomRatio;
    } else {
        return img.naturalWidth >= img.clientWidth * zoomRatio;
    }
}
function adjustImageViewer(el) {
    const canvas = el.querySelector('.image-viewer__canvas')
    const img = el.querySelector('.image-viewer__image');
    const toolbar = el.querySelector('.image-viewer-toolbar');
    const homeEl = toolbar?.querySelector('.image-viewer-toolbar__home');
    const zoomInEl = toolbar?.querySelector('.image-viewer-toolbar__zoom-in');
    const zoomOutEl = toolbar?.querySelector('.image-viewer-toolbar__zoom-out');
    const zoomVisibilityRatio = 0.75;
    let loadViewer = true;

    function setSizing() {
        canvas.style.aspectRatio = `${ img.naturalWidth } / ${ img.naturalHeight }`;

        loadViewer = shouldLoadViewer(img, canvas, el.classList.contains('active'));
        el.classList.toggle('active', loadViewer);
    }

    function initializeViewer() {
        const viewer = OpenSeadragon({
            element: canvas,
            tileSources: {
                type: 'image',
                url: img.src,
            },
            visibilityRatio: loadViewer ? zoomVisibilityRatio : 1.0,
            panHorizontal: loadViewer,
            panVertical: loadViewer,
            drawer: 'canvas',
            minZoomLevel: 1.0,
            maxZoomPixelRatio: 1.0,
            zoomInButton: zoomInEl,
            zoomOutButton: zoomOutEl,
            homeButton: homeEl,
            showFullPageControl: false,
            showRotationControl: false,
            defaultZoomLevel: 1.0,
            zoomPerScroll: 1.0  // This effectively disables zoom on scroll
        });

        // Copy alt text to canvas which has role img (there may be a better way to do this, unsure best handling for WCAG)
        canvas.setAttribute('aria-label', img.alt);

        // Allow scrolling past
        viewer.addHandler('canvas-scroll', function (e) {
            e.preventDefault = false;
        });

        // Awkward, but need to make sure that the viewer is reset to "home" when it gets resized/moved around, and this isn't super smooth with OSD for some reason
        viewer.addHandler('resize', function () {
            // Check if the viewer is already fully loaded
            if (viewer.world.getItemCount() > 0 && viewer.world.getItemAt(0).getFullyLoaded()) {
                // Requires a slight delay for some reason in this case (ugh)
                setTimeout(() => {
                    viewer.viewport.goHome(true);
                }, 10)
            } else {
                // Add a one-time listener for the 'tile-drawn' event if the viewer isn't fully loaded yet
                let tileDrawnHandler = function () {
                    viewer.viewport.goHome(true);
                    viewer.removeHandler('tile-drawn', tileDrawnHandler);
                };
                viewer.addHandler('tile-drawn', tileDrawnHandler);
            }
        });
    }

    // If viewer doesn't exist yet, create it
    let viewer = OpenSeadragon.getViewer(canvas);
    if (!viewer) {
        // Make sure the image is loaded before handling sizing and starting up OSD
        if (img.complete) {
            setSizing();
            initializeViewer()
        } else {
            img.onload = () => {
                setSizing();
                initializeViewer()
            };
        }

    } else {
        // ...otherwise simply update the existing viewer
        loadViewer = shouldLoadViewer(img, canvas, el.classList.contains('active'));

        // Enable or disable zooming ability
        viewer.viewport.visibilityRatio = loadViewer ? zoomVisibilityRatio : 1.0;
        viewer.panHorizontal = loadViewer;
        viewer.panVertical = loadViewer;

        el.classList.toggle('active', loadViewer);
    }
}

export function adjustImageViewers() {
    // Only update an open viewer (update later if these can be in more places)
    document.querySelectorAll('dialog[open] .image-viewer').forEach((el) => {
        adjustImageViewer(el);
    });
}

export function setupImageViewers() {
    // Run once initially
    adjustImageViewers()
}
