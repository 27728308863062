//=================================================
// Expandables
//=================================================

function adjustExpandable(expandable) {
    // Set everything to the correct expanded state
    const toggle = expandable.querySelector('.expandable-toggle');
    const expanded = toggle.getAttribute('aria-expanded') === 'true';
    if (expanded) {
        expandable.classList.add('active');
    } else {
        expandable.classList.remove('active');
    }

    // Need to look through and find items that aren't visible, if they aren't, then show the see more button
    let togglable = false;
    let topLast;
    const items = expandable.querySelectorAll('.expandable-item');
    items.forEach((item) => {
        const topNew = item.getBoundingClientRect().top;
        if (typeof topLast === 'undefined') {
            topLast = topNew;
        }
        // 2 cases for showing the toggle:
        // Case 1: there's hidden (by css) items in the list
        // Case 2: there's wrapping, which could happen moving between breakpoints
        if ((getComputedStyle(item).display === 'none') || topLast !== topNew) {
            togglable = true;
        }

        topLast = topNew;
    });

    if (togglable) {
        expandable.classList.add('expandable--toggle');
        // Update the toggle
        const toggleText = toggle.querySelector('.expandable-toggle__text');
        const toggleCount = toggle.querySelector('.expandable-toggle__count');
        if (expanded) {
            toggleText.innerHTML = 'View fewer';
            toggleCount.innerHTML = '';
        } else {
            toggleText.innerHTML = 'View all';
            toggleCount.innerHTML = `&nbsp;(${ items.length })`;
        }

    } else {
        expandable.classList.remove('expandable--toggle');
    }
}

// This is called elsewhere on screen resize
export function adjustExpandables() {
    document.querySelectorAll('.expandable').forEach((expandable) => {
        adjustExpandable(expandable);
    });
}

export function setupExpandables() {
    document.querySelectorAll('.expandable:not([data-initialized="true"])').forEach((el) => {
        el.dataset.initialized = 'true'; // Only initialize once...

        // Add listeners for clicking on view all button
        const toggle = el.querySelector('.expandable-toggle');
        toggle?.addEventListener('click', () => {
            const expanded = toggle.getAttribute('aria-expanded') === 'true' || false;
            toggle.setAttribute('aria-expanded', !expanded);
            adjustExpandable(el);
        });
    });

    // Run one initial setup
    adjustExpandables();
}
