// This component should be used with GuideLink to animate the transition between pages
import React, { useEffect, useState, createContext } from 'react';
import { setFocus } from '../../utilities';

export const PageTransitionContext = createContext();

export default function PageTransition({ children, page, pathname }) {
    const [loaded, setLoaded] = useState(false);
    const [scrollToTop, setScrollToTop] = useState(false);

    const handleLinkClick = (scrollToTopProp) => {
        setLoaded(false);
        setScrollToTop(!!scrollToTopProp);
    };

    // fade in, scroll to top, and focus H1 each time the path or pagination changes
    // This loads after the page data has been fetched
    useEffect(() => {
        setLoaded(true);

        if (scrollToTop) {
            window.scrollTo(0, 0);
            // setTimeout(() => {
            setFocus();
            // }, 50);
        }
    }, [pathname, page]);

    return (
        <PageTransitionContext.Provider value={ handleLinkClick }>
            <div className={ `guide-transition-wrapper${ loaded ? ' guide-transition-wrapper--loaded' : '' }` }>
                { children }
            </div>
        </PageTransitionContext.Provider>
    );
}
