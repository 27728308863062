// Copy text and update tooltips as a visible indicator
import { copyText } from './utilities';

export default function setupCopyable() {
    document.querySelectorAll('.copyable').forEach((copyable) => {
        if (copyable.dataset.initialized !== 'true') {
            copyable.dataset.initialized = 'true'; // Only initialize once...

            copyable.addEventListener('click', () => {
                let content = copyable.querySelector('.copyable__content');
                // It might be a sibling element, so check that next
                if (!content) {
                    content = copyable.parentElement.querySelector('.copyable__content');
                }

                let value = content.innerText;
                if (content.nodeName === 'INPUT') {
                    value = content.value;
                }
                copyText(value.trim());

                // Prefer to update the tooltip if present, otherwise update the text itself
                 
                const text = copyable.querySelector('.copyable__text');
                if (copyable._tippy) {
                    copyable._tippy.setContent('Copied');
                    setTimeout(() => {
                        copyable._tippy.setContent(copyable.dataset.tooltipContent);
                    }, 2000);
                } else if (text) {
                    const textContent = text.innerText;
                    text.innerText = 'Copied';
                    setTimeout(() => {
                        text.innerText = textContent;
                    }, 2000);
                }
                 
            });
        }
    });
}
