//=================================================
// Cards
//=================================================
import { disableScroll, enableScroll } from './utilities';

// Handle card swiping
function shiftCards(card, shuffle) {
    const width = card.offsetWidth;
    const left = parseInt(card.style.left) || 0;

    // Check if the card has be moved enough or if we're determined to shuffle
    if (Math.abs(left) > width / 2 || shuffle) {
        const cardRail = card.closest('.card-rail');
        const cards = cardRail.querySelectorAll('.card');

        // Re-index the other cards
        cards.forEach((otherCard) => {
            const { index } = otherCard.dataset;
            otherCard.setAttribute('data-index', index - 1);
        });

        // Set card to final index and animate the cards shuffling
        card.setAttribute('data-index', cards.length);
        card.style.transitionProperty = 'left, opacity';
        card.style.left = left > 0 ? '100vw' : '-100vw';
        card.style.opacity = 0;

        setTimeout(() => {
            card.style.transitionProperty = '';
            card.style.left = '';
            card.style.opacity = '';
        }, window.transitionDuration);

        if (useAnalytics) {
            dataLayer.push({
                event: 'cards',
                label: 'swipe',
                value: 'true',
            });
        }
    } else {
        // Animate the card back to the starting position
        card.style.transitionProperty = 'left';
        card.style.left = '0px';

        setTimeout(() => {
            card.style.transitionProperty = '';
        }, window.transitionDuration);
    }
}

// Adjust amount of visible cards, either in their expanded or compacted states
export function adjustCardRail(cardRail) {
    const maxWidth = cardRail.getBoundingClientRect().width;
    let currentWidth = 0;
    cardRail.querySelectorAll('.card').forEach((card) => {
        // Make visible so we can calculate actual width
        card.parentElement.classList.remove('hidden');
        currentWidth += card.parentElement.offsetWidth;
        // If the element would wrap to the next row, hide it
        if (currentWidth > maxWidth) {
            card.parentElement.classList.add('hidden');
        } else {
            card.parentElement.classList.remove('hidden');
        }
    });
}

export function adjustCardRails() {
    document.querySelectorAll('.card-rail').forEach((el) => {
        adjustCardRail(el);
    });
}

// Determine if card swiping is active
function cardSwipeActive(card) {
    return window.isNavSmall && [1, '1'].includes(card.dataset.index);
}

export function setupCards() {
    document.querySelectorAll('.card-rail').forEach((cardRail) => {
        const cards = cardRail.querySelectorAll('.card');
        cards.forEach((card, i) => {
            // Initialize card index values
            card.setAttribute('data-index', i + 1);

            // TODO: there is no handling for non-touch screen controls on small screens, not great? ~ colin
            card.addEventListener('touchstart', (e) => {
                if (cardSwipeActive(card)) {
                    card.setAttribute('data-x', e.touches[0].pageX);
                    card.setAttribute('data-y', e.touches[0].pageY);
                    card.setAttribute('data-time', Date.now());
                    card.setAttribute('data-mode', 'normal');
                }
            });

            card.addEventListener('touchmove', (e) => {
                if (cardSwipeActive(card)) {
                    // Add arbitrary threshold so slight jitter in movement direction is evened out
                    const threshold = 15;
                    const x = e.touches[0].pageX;
                    const y = e.touches[0].pageY;
                    const xDiff = Math.abs(x - parseInt(card.dataset.x));
                    const yDiff = Math.abs(y - parseInt(card.dataset.y));

                    // ...if safari ever supports touch-action: none, this can be done differently ~ colin
                    // If the card mode hasn't been set, we can check if it should...
                    if (card.dataset.mode === 'normal') {
                        // Case 1: more y than x, scrolling the window
                        if ((xDiff < threshold) && (yDiff >= threshold)) {
                            card.setAttribute('data-mode', 'scroll');
                        } else if ((xDiff >= threshold) && (yDiff < threshold)) {
                            // Case 2: more x than y, swiping the card
                            card.setAttribute('data-mode', 'swipe');
                            // As a non-passive listener, preventDefault should block scrolling
                            e.preventDefault();
                            disableScroll();
                        }
                    }

                    // Move the card if we're swiping
                    if (card.dataset.mode === 'swipe') {
                        card.style.left = `${ x - parseInt(card.dataset.x) }px`;
                    }
                }
            }, { passive: false });

            // Handle cards when touch ends
            card.addEventListener('touchend', () => {
                // Re-enable scrolling
                card.setAttribute('data-mode', 'normal');
                enableScroll();

                if (cardSwipeActive(card)) {
                    // If they're swiping quickly take the hint and shift the cards
                    const left = parseInt(card.style.left) || 0;
                    shiftCards(card, (parseInt(card.dataset.time) + 500 > Date.now()) && (Math.abs(left) > 0));
                }
            });

            // Analytics events
            if (useAnalytics) {
                card.addEventListener('click', () => {
                    dataLayer.push({
                        event: 'cards',
                        label: 'click',
                        value: card.querySelector('a').getAttribute('href'),
                    });
                });
            }
        });
    });

    adjustCardRails();
}
