//=================================================
// Individual guide
//=================================================

import React, { useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import Navigation from '../Navigation';
import PlaylistItem from './PlaylistItem';
import Stop from './Stop';
import { cleanText, createMarkup, setFocus } from '../../../utilities';
import { fetchWithCache, getLanguage, getStopsContainingType, getType, pushGuideEvent } from '../../utilities';

export async function loader({ params }) {
    const { guideId } = params;

    try {
        const result = await fetchWithCache(`/api/mobile-guides/${ guideId }`);

        return { guide: result.data };
    } catch (error) {
        console.error('Error GuideList', error);
        return null;
    }
}

function getCurrentStop(stopId, stops) {
    const selectedStop = stops.filter((stop) => stop.data.id === stopId)[0];

    return selectedStop?.data || null;
}

export default function Guide() {
    const { guide } = useLoaderData();
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamsObj = Object.fromEntries(searchParams);
    const language = getLanguage(searchParams);
    const stopType = getType(searchParams);
    const stopsAccess = getStopsContainingType(guide, language, 'access');
    const stopsKids = getStopsContainingType(guide, language, 'kids');
    const stops = getStopsContainingType(guide, language, stopType);
    // TODO: this logic needs to be reworked ~ colin
    const stop = getCurrentStop(searchParamsObj.stop, stops);
    const [stopShouldPlay, setStopShouldPlay] = useState(false);

    const handleStopChange = (e, selectedStop) => {
        e.preventDefault();

        setStopShouldPlay(true);
        const newSearchParams = { ...searchParamsObj, stop: selectedStop.id };
        delete newSearchParams.stop_item;

        // Add an extra browser history item on mobile so that the stop is selected when hitting the back button ;-(
        const newSearchParamsString = new URLSearchParams(newSearchParams).toString();
        const currentPath = `${ window.location.pathname }?${ newSearchParamsString }`;
        if (window.isNavSmall) {
            window.history.pushState(window.history.state, '', currentPath);
            newSearchParams.open = 'true';
        }

        // actually load the stop
        setSearchParams(newSearchParams);

        let value = cleanText(selectedStop.attributes.title);
        if (selectedStop.attributes.stop_number) {
            value += ` (${ selectedStop.attributes.stop_number })`;
        }

        pushGuideEvent({ label: 'stop', value, type: '' }, guide);
    };

    function handleGuideTypeChange(type, e) {
        e.preventDefault();

        setSearchParams({ ...searchParamsObj, type });

        // Set focus on the first stop in the list
        setFocus('.playlist-item');

        pushGuideEvent({ label: 'select', value: type, type }, guide);
    }

    // Description
    let guideDescription;
    if (guide.attributes.description_es && language === 'es') {
        guideDescription = <div className="body-large"
                                dangerouslySetInnerHTML={ createMarkup(guide.attributes.description_es) }/>;
    } else if (guide.attributes.description) {
        guideDescription =
            <div className="body-large" dangerouslySetInnerHTML={ createMarkup(guide.attributes.description) }/>;
    }

    // All
    const allButton = <button className={ `btn${ stopType === 'all' ? ' active' : '' }` }
                              onClick={ (e) => handleGuideTypeChange('all', e) }>
        { I18n[language].all }
    </button>;

    // Kids
    const kidsEnglishOnly = (stopsKids.length > 0) && (getStopsContainingType(guide, language, 'kids', false).length === 0);
    const kidsButton = <button className={ `btn${ stopType === 'kids' ? ' active' : '' }` }
                               disabled={ stopsKids.length === 0 }
                               onClick={ (e) => handleGuideTypeChange('kids', e) }>
        { kidsEnglishOnly ? I18n[language].kids_english_only : I18n[language].kids }
    </button>;

    // Access
    const accessEnglishOnly = (stopsAccess.length > 0) && (getStopsContainingType(guide, language, 'access', false).length === 0);
    const accessButton = <button className={ `btn${ stopType === 'access' ? ' active' : '' }` }
                                 disabled={ stopsAccess.length === 0 }
                                 onClick={ (e) => handleGuideTypeChange('access', e) }>
        { accessEnglishOnly ? I18n[language].access_english_only : I18n[language].access }
    </button>;

    useEffect(() => {
        document.title = `${ cleanText(guide.attributes.title) } | Whitney Museum of American Art`;

        // Google Analytics page load event
        pushGuideEvent({ label: 'guide', value: cleanText(guide.attributes.title) }, guide);
    }, []);

    return (
        <>
            <Navigation guide={ guide }/>
            { guideDescription }
            <div className="guide active">
                <div className="guide__primary">
                    <div className="btn-group mb-l">
                        { allButton }{ kidsButton }{ accessButton }
                    </div>

                    <ul className="guide-playlist m-0 p-0 active">
                        { stops.map((thisStop, index) => <PlaylistItem
                            key={ thisStop.data.id }
                            playlistStop={ thisStop.data }
                            index={ index }
                            stop={ stop }
                            language={ language }
                            handleStopChange={ handleStopChange }
                        />) }
                    </ul>
                </div>
                <div className="guide__secondary">
                    { stop && (
                        <Stop guide={ guide }
                          stop={ stop }
                          language={ language }
                          stopShouldPlay={ stopShouldPlay }
                          onStopShouldPlay={ setStopShouldPlay } />
                    ) }
                </div>
            </div>

        </>
    );
}
