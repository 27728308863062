//=================================================
// Guide initialize
//=================================================

import React from 'react';
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';

import ErrorPage from './src/ErrorPage';
import Main from './src/Main';
import GuideList, { loader as guideListLoader } from './src/GuideList';
import Guide, { loader as guideLoader } from './src/Guide';
import { getBasename, isMobileGuide } from './utilities';

export function setupGuides() {
    const rootEl = document.getElementById('guide-root');
    if (!rootEl) {
        return;
    }

    const root = createRoot(rootEl);
    if (!root) {
        return;
    }

    // Disable turbo on guide pages since it clashes with react router (especially on /audio-guides)
    Turbo.session.drive = false;

    const basename = getBasename();
    const mobileGuide = isMobileGuide();
    const router = createBrowserRouter(
        [
            {
                path: '/',
                element: <Main mobileGuide={ mobileGuide }/>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        path: '/',
                        element: <GuideList mobileGuide={ mobileGuide }/>,
                        loader: guideListLoader,
                    },
                    {
                        path: '/:guideId',
                        element: <Guide mobileGuide={ mobileGuide }/>,
                        loader: guideLoader,
                    },
                ],
            },
        ],
        { basename },
    );

    root.render(
        <React.StrictMode>
            <RouterProvider router={ router }/>
        </React.StrictMode>,
    );
}
