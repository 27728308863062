export default function setupTabFocus() {
    // Prevents focus indicators on mouse click, but leaves them active for keyboard tabs
    document.addEventListener('mousedown', () => {
        document.body.classList.remove('show-focus');
    });

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
            document.body.classList.add('show-focus');
        }
    });
}
