/**
 * Date range picker used on the events calendar
 */
import React, { useEffect, useRef, useState } from "react";
// vendor css is imported in day-picker.scss
import { DayPicker } from "react-day-picker";

import { mountReactComponent } from "../utilities";
import ClickAwayListener from "./ClickAwayListener";

function parseDate(dateIso) {
    if (!dateIso) return null;

    return new Date(`${ dateIso } 00:00:00`);
}

function formatDaterange(from, to) {
    if (!from && !to) return '';

    const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long', 
        day: 'numeric',
    });

    if (!to) {
        return formatter.format(from) + '–';
    }

    return formatter.formatRange(from, to);
}

const Icon = ({ className, icon, orientation }) => {
    if (!icon) return;

    const angles = {
        up: '180',
        right: '270',
        down: '0',
        left: '90',
    }
    const rotation = angles[orientation];

    return (
        <span
            className={ className }
            style={ { display: 'flex', alignItems: 'center', transform: `rotate(${ rotation }deg)` } }
            dangerouslySetInnerHTML={ {__html: decodeURIComponent(icon)} }
        />
    );
}

function DayPickerWrapper({ datesWithEvents: datesWithEventsRaw, icon, initialDateFrom, initialDateTo, noEvents, rootElement }) {
    const [open, setOpen] = useState(false);
    const defaultDateFrom = parseDate(initialDateFrom);
    const defaultDateTo = parseDate(initialDateTo);
    const [selected, setSelected] = useState({
        from: defaultDateFrom,
        to: defaultDateTo,
    });
    const { from, to } = selected || {};
    const toggleRef = useRef();
    const dateRangeText = formatDaterange(from, to);
    const datesWithEvents = JSON.parse(datesWithEventsRaw);

    const calculateNumberOfMonths = () => {
        if (window.isMedium || window.isSmall) {
            return 1;
        } else {
            return 2;
        }
    }
    const [numberOfMonths, setNumberOfMonths] = useState(calculateNumberOfMonths());

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setNumberOfMonths(calculateNumberOfMonths());
    };

    const formatDate = (date) => (date ? date.toISOString().split('T')[0] : '');

    const handleClickButton = () => {
        setOpen(!open);
    };

    const handleClickAllDates = () => {
        setSelected({ from: '', to: '' });

        // this will probaby not be necessary if I refactor the whole Calendar to no longer use the lists feature
        setTimeout(() => {
            rootElement.closest('form').requestSubmit();
        }, 30);
    };

    const isDayDisabled = (day) => (
        !datesWithEvents.includes(formatDate(day))
    );


    const handleSelect = (selectedDate) => {
        setSelected(selectedDate);
    };

    const handleSubmit = () => {
        rootElement.closest('form').requestSubmit();
        setOpen(false);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const classNames = ['day-picker-popup'];

    if (open) classNames.push('open');

    return (
        <>
            <div className={ `day-picker-buttons ${ noEvents === 'true' ? 'day-picker-no-events' : '' }` }>
                <button className={ `day-picker-button${ (!from && !to && !open) ? ' active' : '' }` } type="button" onClick={ handleClickAllDates }>All dates</button>
                <button
                    className={ `day-picker-button${ (from || to || open) ? ' active' : '' }` }
                    onClick={ handleClickButton }
                    ref={ toggleRef }
                    type="button"
                >
                    Pick a date <Icon icon={ icon } orientation={ open ? 'up' : 'down' } className="ml-t" />
                </button>

                { from && <input type="hidden" name="date_from" id="date_from" value={ formatDate(from) } /> }
                { to && <input type="hidden" name="date_to" id="date_to" value={ formatDate(to) } /> }
            </div>
            <ClickAwayListener
                onClickAway={ handleClickAway }
                buttonRef={ toggleRef }
                className={ classNames.join(' ') }
            >
                <DayPicker
                    components={ { Chevron: (props) => <Icon icon={ icon } { ...props } /> } }
                    defaultMonth={ defaultDateFrom || new Date() }
                    disabled={ isDayDisabled }
                    formatters={ { formatWeekdayName: (day) => day.toLocaleDateString('en-US', { weekday: 'narrow' }) } }
                    mode="range"
                    numberOfMonths={ numberOfMonths }
                    onSelect={ handleSelect }
                    selected={ selected }
                />

                { (from || to) && (
                    <div className="mt-m body-large day-picker-selected"><strong>Selected:</strong> { dateRangeText }</div>
                ) }

                <button className="btn btn--strong btn--stretch day-picker-submit" disabled={ !from && !to } type="button" onClick={ handleSubmit }>GO</button>
            </ClickAwayListener>
        </>
    );
}

const observer = new MutationObserver((mutationsList, _observer) => {
    for (const mutation of mutationsList) {
        mutation.addedNodes.forEach(node => {
            if (node.id === 'day-picker') {
                mountDayPicker();
            }
        });
    }
});

function mountDayPicker() {
    document.querySelectorAll('.day-picker').forEach((rootElement) => {
        mountReactComponent(
            rootElement,
            <DayPickerWrapper { ...rootElement.dataset } rootElement={ rootElement } />
        );
    });
}

export default function setupDayPickers() {
    const element = document.querySelector('.events-form');

    if (!element) return;

    mountDayPicker();

    // Re-mount the component if Turbo Streams replaces it with new props
    observer.observe(element, { childList: true, subtree: true });
}