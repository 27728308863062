import React, { useEffect, useRef } from "react";

/**
 * Triggers an event handler when clicking anywhere but on the child elements
 */
export default function ClickAwayListener ({ buttonRef, children, className, onClickAway }) {
    const node = useRef();

    const handleClickAway = (e) => {
        // Don't trigger if clicking on the element or on an optional button element
        if (
            node.current.contains(e.target) ||
            (
                buttonRef &&
                buttonRef.current.contains(e.target)
            )
        ) return;

        onClickAway();
    };

    useEffect(() => {
        window.addEventListener('click', handleClickAway, true);

        return () => {
            window.removeEventListener('click', handleClickAway, true);
        };
    }, []);

    return (
        <div ref={ node } className={ className }>
            { children }
        </div>
    );
}