//=================================================
// Accordions
//=================================================
import { slideDown, slideUp } from './utilities';

export function setupAccordions() {
    document.querySelectorAll('.accordion-component').forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...
            const hours = el.querySelector('.accordion-component__hours');
            const toggle = el.querySelector('.accordion-component__toggle');
            const short = el.querySelector('.accordion-component__short');
            const long = el.querySelector('.accordion-component__long');

            [short, toggle].filter(ele => ele !== null).forEach((ele) => {
                ele.addEventListener('click', () => {
                    const expanded = toggle.getAttribute('aria-expanded') === 'true';
                    toggle.setAttribute('aria-expanded', !expanded);

                    if (expanded) {
                        if (hours) {
                            hours.classList.remove('active');
                        }
                        slideUp(long);
                    } else {
                        if (hours) {
                            hours.classList.add('active');
                        }
                        slideDown(long);
                    }
                });
            })
        }
    });
}
