//=================================================
// Audio
//=================================================

import { slideDown, slideUp } from './utilities';

// Nicely format time durations
function prettyTime(inputSeconds) {
    let seconds = Math.floor(inputSeconds % 60);
    if (seconds < 10) {
        seconds = `0${ seconds }`;
    }

    const minutes = Math.floor(inputSeconds / 60);
    return `${ minutes }:${ seconds }`;
}

// Setup events for touch or mouse
// Update to re-happen on resize
function updateInteractionEvents() {
    window.startEvent = 'mousedown';
    window.moveEvent = 'mousemove';
    window.endEvent = 'mouseup';

    // This is problematic because a device can have both mice and touchscreens
    if ('ontouchstart' in window) {
        window.startEvent = 'touchstart';
        window.moveEvent = 'touchmove';
        window.endEvent = 'touchend';
    }
}

export function setupAudioPlayer(wrapper) {
    if (!wrapper.dataset.initialized) {
        wrapper.dataset.initialized = 'true'; // Only initialize once...

        updateInteractionEvents();

        const audio = wrapper.querySelector('audio');
        const player = wrapper.querySelector('.audio-player');
        const playButton = wrapper.querySelector('.audio-player__play');
        const progress = wrapper.querySelector('.audio-player__progress');
        const timeline = wrapper.querySelector('.audio-player__timeline');
        const elapsed = wrapper.querySelector('.audio-player__elapsed');
        const remaining = wrapper.querySelector('.audio-player__remaining');
        const skipBackButton = wrapper.querySelector('.audio-player__skip-back');
        const skipForwardButton = wrapper.querySelector('.audio-player__skip-forward');
        const transcriptToggle = wrapper.querySelector('.audio-player__transcript');
        const transcriptWrapper = wrapper.querySelector('.audio-wrapper__transcript-wrapper');
        const transcript = wrapper.querySelector('.audio-wrapper__transcript');

        let duration = 0;
        let lastClientX = 0;

        // Get click as decimal of the total timeline width
        function clickPercent() {
            return (lastClientX - timeline.getBoundingClientRect().left) / timeline.offsetWidth;
        }

        // Moves progress on drag
        function moveProgress(e) {
            // Handle touch and click events depending on input
            if (e.touches) {
                lastClientX = e.touches[0].clientX;
            } else {
                lastClientX = e.clientX;
            }

            const newWidth = lastClientX - timeline.getBoundingClientRect().left;
            const timelineWidth = timeline.offsetWidth;
            if (newWidth >= 0 && newWidth <= timelineWidth) {
                progress.style.width = `${ newWidth }px`;
            } else if (newWidth < 0) {
                progress.style.width = '0px';
            } else if (newWidth > timelineWidth) {
                progress.style.width = `${ timelineWidth }px`;
            }
        }

        function timeCounterUpdate() {
            // Provide same rounded time to both functions so they round/update at the same time
            const roundedSeconds = Math.floor(audio.currentTime);
            elapsed.textContent = `${ prettyTime(roundedSeconds) }`;
            remaining.textContent = `-${ prettyTime(duration - roundedSeconds) }`;
        }

        // Synchronizes play head position and button state with current point in audio
        function timeUpdate() {
            // If audio ends naturally OR there's a disconnect between play state, update the play button accordingly
            if ((audio.paused && playButton.classList.contains('playing')) ||
                audio.currentTime >= duration) {
                playButton.classList.add('paused');
                playButton.classList.remove('playing');
            } else if (!audio.paused && !playButton.classList.contains('playing')) {
                playButton.classList.add('playing');
                playButton.classList.remove('paused');
            }

            // Update the progress bar and time counter
            progress.style.width = `${ timeline.offsetWidth * (audio.currentTime / duration) }px`;
            timeCounterUpdate();
        }

        function progressEnd(e) {
            window.removeEventListener(window.moveEvent, moveProgress, true);
            window.removeEventListener(window.endEvent, progressEnd, false);
            // Update current time
            audio.currentTime = duration * clickPercent(e);
            audio.addEventListener('timeupdate', timeUpdate, false);
        }

        // Handle interaction with timeline
        function progressStart(e) {
            moveProgress(e);
            window.addEventListener(window.moveEvent, moveProgress, true);
            window.addEventListener(window.endEvent, progressEnd, false);
            audio.removeEventListener('timeupdate', timeUpdate, false);
        }

        // Handle play and Pause
        function playHandler(e) {
            e.preventDefault();

            if (audio.paused) {
                playButton.classList.add('loading');
                audio.play().then(() => {
                    playButton.classList.add('playing');
                    playButton.classList.remove('paused', 'loading');
                    playButton.setAttribute('aria-label', 'Pause');
                }, function (err) {
                    console.error(err);
                    playButton.classList.remove('loading');
                });

            } else {
                audio.pause();
                playButton.classList.add('paused');
                playButton.classList.remove('playing', 'loading');
                playButton.setAttribute('aria-label', 'Play');
            }
        }

        function skipBackHandler() {
            audio.currentTime -= 10;
        }

        function skipForwardHandler() {
            audio.currentTime += 10;
        }

        // Set media session info so media shows properly on lockscreens
        function setMediaSession() {
            if ('mediaSession' in navigator) {
                const metadata = {
                    title: audio.dataset.label,
                    artist: audio.dataset.artist ? audio.dataset.artist : 'Whitney Museum of American Art',
                };

                if (audio.dataset.image) {
                    metadata.artwork = [{ src: audio.dataset.image }];
                }

                navigator.mediaSession.metadata = new MediaMetadata(metadata);
            }
        }

        // Set audio duration
        if (audio.readyState > 2) {
            // Audio load events have already fired, update the duration
            duration = audio.duration;
            timeCounterUpdate();
            setMediaSession();
        } else {
            // Otherwise add a listener for when duration is available
            audio.addEventListener('loadedmetadata', () => {
                duration = audio.duration;
                timeCounterUpdate();
                setMediaSession();
            }, false);
        }

        // Handle transcript click
        function transcriptHandler(e) {
            e.preventDefault();

            const expanded = transcriptToggle.getAttribute('aria-expanded') === 'true';
            transcriptToggle.setAttribute('aria-expanded', expanded ? 'false' : 'true');
            if (expanded) {
                transcriptToggle.classList.remove('active');
                slideUp(transcriptWrapper);
            } else {
                transcriptToggle.classList.add('active');
                slideDown(transcriptWrapper);
            }
        }

        playButton.addEventListener('click', playHandler);
        skipBackButton.addEventListener('click', skipBackHandler);
        skipForwardButton.addEventListener('click', skipForwardHandler);
        transcriptToggle.addEventListener('click', transcriptHandler);

        // If there is a transcript, enable the toggle
        if (transcript && transcript.innerHTML) {
            transcriptToggle.classList.remove('disabled');
        }

        audio.addEventListener('timeupdate', timeUpdate, false);

        // Handle dragging timeline
        timeline.addEventListener(window.startEvent, progressStart, false);
        // Handle clicking timeline
        timeline.addEventListener('click', (e) => {
            audio.currentTime = duration * clickPercent(e);
        }, false);

        // Update the progress bar when window resizes since it's based on available space
        window.addEventListener('resize', () => {
            // No divide by zero please
            let newWidth = 0;
            if ((audio.currentTime !== 0) && (duration !== 0)) {
                newWidth = timeline.offsetWidth * (audio.currentTime / duration);
            }
            progress.style.width = `${ newWidth }px`;
        });

        // Fade in the player
        player.classList.add('active');



        if (useAnalytics) {
            audio.addEventListener('play', () => {
                // Only track a play once
                if (audio.dataset.trackedPlay !== 'true') {
                    dataLayer.push({
                        event: 'audio',
                        label: 'play',
                        value: audio.dataset.label,
                    });

                    audio.dataset.trackedPlay = 'true';
                }
            });
        }
    }
}

export function setupAudio() {
    // Setup native players
    document.querySelectorAll('.audio-wrapper').forEach((el) => {
        setupAudioPlayer(el);
    });

    // Setup embeds (i.e. Spotify)
    document.querySelectorAll('.audio-embed').forEach((el) => {
        // Spotify iFrames can have bad height values, so set them to something consistent
        const spotifyIframe = el.querySelector('iframe[src^="https://open.spotify.com"]');
        if (spotifyIframe) {
            spotifyIframe.height = 420; // Arbitrary, but takes up reasonable room at cleanly holds 4 items + player
        }
    });
}
