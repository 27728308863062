// The HTML for the audio player is rendered at views/guide/show.html.erb
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pushGuideEvent, safelyParseJSON } from '../utilities';

export default function AudioSetup() {
    const { pathname } = useLocation();
    const isHomepage = pathname === '/';
    // get current guide title for analytics
    const guideName = !isHomepage ? document.title.split(' | ')[0] : '';
    const navigate = useNavigate();
    const audioWrapper = document.querySelector('.audio-wrapper');

    const handleAudioClick = (e) => {
        // Only open on small screens and if it wasn't a tap on a button
        if (
            e.target.tagName !== 'BUTTON' &&
            !e.target.classList.contains('audio-player__progress') &&
            !e.target.classList.contains('audio-player__timeline')
        ) {
            // If on guide index page, navigate to the stop item and keep playing the audio
            // Get the previous location saved in the audio player DOM and navigate there
            const { guideId, params } = safelyParseJSON(audioWrapper.dataset.activeItem) || {};

            if (!guideId || !params) return;

            let activeItemParams = params;
            if (window.isNavSmall) activeItemParams.open = true;
            activeItemParams = new URLSearchParams(activeItemParams).toString();
            const activeItemUrl = `/${ guideId }?${ activeItemParams }`;

            // don't navigate if you're already on the stop
            if (window.location.pathname.includes(activeItemUrl)) return;

            navigate(activeItemUrl);
        }
    };

    // Handle clicks on the audio player
    useEffect(() => {
        audioWrapper?.addEventListener('click', handleAudioClick);

        return () => {
            audioWrapper?.removeEventListener('click', handleAudioClick);
        };
    }, []);

    useEffect(() => {
        // Handle loading next stop
        function nextStop() {
            // If there are more stops, load in the next one
            const activeEl = document.querySelector('.playlist-item.active');
            const next = activeEl ? activeEl.parentElement.nextElementSibling : null;
            if (next) next.querySelector('.playlist-item').click();

            pushGuideEvent({ label: 'click', guide_name: guideName, value: 'next' });
        }

        // Handle loading previous stop
        function previousStop() {
            // If there are more stops, load in the previous one
            const activeEl = document.querySelector('.playlist-item.active');
            const prev = activeEl ? activeEl.parentElement.previousElementSibling : null;
            if (prev) prev.querySelector('.playlist-item').click();

            pushGuideEvent({ label: 'click', guide_name: guideName, value: 'previous' });
        }

        // Handle previous stop click
        const audioLeftEl = document.querySelector('.audio-player__left');
        audioLeftEl?.addEventListener('click', (e) => {
            e.preventDefault();
            previousStop();
        });

        // Handle next stop click
        const audioRightEl = document.querySelector('.audio-player__right');
        audioRightEl?.addEventListener('click', (e) => {
            e.preventDefault();
            nextStop();
        });

        // Add listeners for lockscreen controls
        if ('mediaSession' in navigator) {
            navigator.mediaSession.setActionHandler('previoustrack', previousStop);
            navigator.mediaSession.setActionHandler('nexttrack', nextStop);
        }

        // Set last update timestamp, if there isn't one already
        if (!localStorage.getItem('guide-last-update')) {
            localStorage.setItem('guide-last-update', Date.now());
        }
    }, []);

    return null;
}
