import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ErrorPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/') navigate('/');
  }, []);

  return null;
}
