// This component must be a child of the PageTransition component to animate properly
// It should also be used everywhere in this React app instead of the official Link component made by React Router
import React, { useContext } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { getBasename } from '../utilities';
import { PageTransitionContext } from './PageTransition';

export default function GuideLink({ attributes, children, className, onClick, scrollToTop, to }) {
    const navigate = useNavigate();
    const { pathname, search } = useResolvedPath(to);
    const onPageTransition = useContext(PageTransitionContext);

    const handleClick = (e) => {
        e.preventDefault();

        // pass through any click handler as a prop
        if (typeof onClick === 'function') {
            onClick(e);
        }

        // trigger fade out in PageTransition component
        if (typeof onPageTransition === 'function') {
            onPageTransition(scrollToTop);
        }

        // Disabling for now ~ colin (9/23)
        // wait until fade out is done before navigating to the next page
        // transitionDurationFast is the same as variables.scss -> --duration--fast
        // setTimeout(() => {
        navigate(to);
        // }, window.transitionDurationFast);
    };

    return (
        <a href={ `${ getBasename() }${ pathname }${ search }` }
           className={ className }
           onClick={ handleClick }
           { ...attributes }>
            { children }
        </a>
    );
}
