import React from 'react';

import { useSearchParams } from 'react-router-dom';
import GuideLink from '../../components/GuideLink';
import { pushGuideEvent } from '../../utilities';

export default function PaginationLink({ className, page, children }) {
    const [searchParams] = useSearchParams();
    const params = {
        ...Object.fromEntries(searchParams),
        page,
    };
    const to = `?${ new URLSearchParams(params).toString() }`;

    const handleLinkClick = () => {
        pushGuideEvent({ label: 'page', value: page });
    };

    return <GuideLink
        attributes={ { 'aria-label': 'Next', role: 'button' } }
        className={ className }
        onClick={ handleLinkClick }
        scrollToTop={ true }
        to={ to }>
        { children }
    </GuideLink>;
}
