export default function setupTicketSelector() {
    document.querySelectorAll('.event-detail__selector').forEach((el) => {
        // Reset ticket select box value if navigating to the page via back button
        setTimeout(() => el.value = '', 50);

        if (el.dataset.initialized === 'true') {
            return;
        }

        el.dataset.initialized = 'true'; // Only initialize once for Turbo

        el.addEventListener('change', () => {
            if (el.value) window.location = el.value;
        });
    });

    // Show the sticky header on scroll
    document.querySelectorAll('.event-detail__wrap').forEach((wrapper) => {
        const header = wrapper.querySelector('.event-detail-header');

        // Create the IntersectionObserver instance
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    wrapper.classList.remove('scrolled');
                } else {
                    wrapper.classList.add('scrolled');
                }
            });
        });

        // Start observing the element
        observer.observe(header);
    });
}
